<template>
  <v-app id="inspire">
    <v-system-bar color="darken-3">
      <v-app-bar-nav-icon
        @click.stop="openCloseDrawer"
        class="sm-d-block"
      ></v-app-bar-nav-icon>
    </v-system-bar>
    <the-side-menu :is-open="drawerIsOpen"></the-side-menu>
    <v-main>
      <v-container class="py-8 px-6" fluid>
        <router-view></router-view>
      </v-container>
      <v-fab-transition>
        <v-btn
          color="secondary"
          fab
          large
          dark
          bottom
          right
          class="btn-fab"
          @click="setDarkMode"
        >
          <v-icon>mdi-theme-light-dark</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'Home',

  components: {
    TheSideMenu: () => import('@/components/TheSideMenu'),
  },

  data: () => ({
    baseDialog: false,
    confirmDialog: false,
    currentFormComponent: '',
    drawer: null,
    drawerIsOpen: true,
    isOpen: true,
    itemToEdit: {},
    itemToDelete: null,
  }),

  methods: {
    openCloseDrawer() {
      this.drawerIsOpen = !this.drawerIsOpen;
    },
    setDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>
<style scoped lang="scss">
.btn-fab {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
